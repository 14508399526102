var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "month" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "month",
                  placeholder: "选择月",
                  format: "yyyy 年 MM 月",
                  "value-format": "yyyyMM",
                },
                model: {
                  value: _vm.form.month,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "month", $$v)
                  },
                  expression: "form.month",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "depart_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "请选择部门" },
                  model: {
                    value: _vm.form.depart_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "depart_id", $$v)
                    },
                    expression: "form.depart_id",
                  },
                },
                _vm._l(_vm.groupSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.text },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "attend_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "考勤分组" },
                  model: {
                    value: _vm.form.attend_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "attend_id", $$v)
                    },
                    expression: "form.attend_id",
                  },
                },
                _vm._l(_vm.attendanceSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "user_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "员工姓名、电话",
                  },
                  model: {
                    value: _vm.form.user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "user_id", $$v)
                    },
                    expression: "form.user_id",
                  },
                },
                _vm._l(_vm.peopleSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.user_id, label: i.user_name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.clearForm } },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出汇总"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExport2 } }, [
                _vm._v("导出明细"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                align: "center",
              },
            })
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "100px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCheck(row)
                          },
                        },
                      },
                      [_vm._v("查看详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("detail", { ref: "detail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }